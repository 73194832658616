import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import AddButton from 'components/action/AddButton';
import IndicatorEditionModal from 'components/Modal/IndicatorEditionModal';

import SubHeaderPortal from 'layout/header/SubHeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

import { MiniSlider } from 'components/controls/MiniSlider';
import {
	getItemAlteredByScenario,
	vulnerabilityIndicators
} from 'utils/rules';
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale';

import Table from 'components/Table';
import { Button, Tooltip } from 'antd';
import Weight from 'components/controls/Weight';
import UIContext from 'components/Utils/UIContext';
import { SideMenu } from 'layout/SideMenu';

import './Indicators.sass';
import { useIndicatorController } from 'hooks/useIndicatorController';
import dayjs from 'dayjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MaskableComponent } from 'components/Tutorial/MaskableComponent';
import Tutorial from 'components/Tutorial/Tutorial';

const indicatorsLimit = 20;

export const Indicators = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const params: any = useParams();

	const { createIndicator, updateIndicator, deleteIndicator } = useIndicatorController();

	const { t } = useTranslation();

	const { indicators = [] } = props;

	const allScenarios = (props.scenarios || []).concat([{ id: null }]);
	let alteredIndicators = sortBy(
		indicators.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index,
			key: c.id || `indicator-${index}` // Ajout d'une clé unique pour chaque élément
		})
		),
		'name'
	);

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: vulnerabilityIndicators(
				indicators.map((c) => getItemAlteredByScenario(c, sc))
			)
		};
	});

	vulnerabilities.push({
		id: null,
		name: t('models.scenario.baseline'),
		vulnerability: vulnerabilityIndicators(indicators)
	});

	// Create
	const handleCreateIndicator = async () => {
		props.pushModal(
			<IndicatorEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						reference: values.reference || '',
						evaluatedAt: values.evaluatedAt || ''
					};

					let result = await createIndicator(params.id, variables, {}, props.scenario);

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
					reference: '',
					evaluatedAt: null,
				}}
			></IndicatorEditionModal>
		);
	};


	// Update
	const handleUpdateIndicator = async (id: string, values: Object) => {
		updateIndicator(id, indicators || [], props.scenario, values);
	};

	const askDeleteConfirmation = async (id?: string) => {
		const confirmProps = {
			title: t('models.indicator.messages.delete_confirmation'),
			message: t('models.indicator.messages.delete_confirmation_explanation'),
			onConfirm: () => deleteIndicator(params.id, id),
			onHide: props.popModal,
			t
		};

		props.pushModal(
			<ConfirmModal {...confirmProps} />
		);
	};

	const columns = [
		{
			title: '',
			dataIndex: 'edit',
			key: 'edit',
			width: 50,
			render: (text, record) => (
				<Tooltip title='Edit'>
					<Button
						shape="circle"
						disabled={false}
						style={{ opacity: 1 }}
						onClick={
							props.disabled
								? () => { }
								: () => {
									setEditedItem(record);
								}
						}
					>
						<FontAwesomeIcon
							icon={faEdit}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial',
								opacity: props.disabled ? 0.5 : 1
							}}
						/>
					</Button>
				</Tooltip>
			)
		},
		{
			title: '#',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			width: 60,
			sortable: (a, b) => {
				const scoreA = (a.reference || ("I" + (a.index + 1))).toLocaleLowerCase()
				const scoreB = (b.reference || ("I" + (b.index + 1))).toLocaleLowerCase()

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, record) => {
				return record.reference || ("I" + ((record.index + 1 + "").padStart(2, '0')))
			}
		},
		{
			title: t("models.indicator.fields.evaluated_at"),
			dataIndex: 'evaluatedAt',
			key: 'evaluatedAt',
			className: 'nowrap',
			width: 140,
			sortable: (a, b) => {
				if (!b.evaluatedAt) return -1
				if (!a.evaluatedAt) return 1
				if (a.evaluatedAt && b.evaluatedAt && a.evaluatedAt < b.evaluatedAt) return -1;
				if (a.evaluatedAt && b.evaluatedAt > b.evaluatedAt) return 1;
				return 0
			},
			render: (text, record, index) => {
				return <Tooltip title={record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY HH[h]mm') : ''}>
					<div>
						{record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''}
					</div>
				</Tooltip>
			}
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			className: 'w-50',
			sortable: true,
			ellipsis: true,
			render: (text, record) => {
				return (
					<div className="name w-100">
						{record.name}
						{record.description ? (
							<div className="description">
								{record.description}
							</div>
						) : (
							''
						)}
					</div>
				);
			}
		},
		{
			title: 'Status',
			dataIndex: 'trend',
			key: 'trend',
			width: 200,
			sortable: (a, b) => {
				const scoreA = parseInt(a.trend)
				const scoreB = parseInt(b.trend)

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, item) => {
				return (
					<MiniSlider
						className="mr-2"
						key={item.id}
						value={item.trend}
						min={0}
						max={100}
						style={{ width: '100%', flexShrink: 0 }}
						onChange={(val) =>
							handleUpdateIndicator(item.id, { trend: val })
						}
						additionalMarkers={(allScenarios).map((sc) => {
							return {
								id: sc.id,
								name: sc.name || t("models.scenario.baseline"),
								value: getItemAlteredByScenario(item, sc).trend
							};
						})}
					></MiniSlider>
				);
			}
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',
			sortable: true,
			width: 100,
			render: (text, record) => {
				return (
					<Weight
						style={{ marginRight: '0.5rem' }}
						onClick={(values: any) => {
							let newWeight = record.weight
								? record.weight + 1
								: 1;
							if (newWeight > 3) newWeight = 0;
							handleUpdateIndicator(record.id, {
								...record,
								weight: newWeight
							});
						}}
						disabled={false}
					>
						{record.weight}
					</Weight>
				);
			}
		}
	];

	const uiContext = useContext(UIContext);

	return (
		<div className="Indicators d-flex w-100">
			<SubHeaderPortal>
				<Tutorial
					type="stakes"
					context={{ ...props }}
					executeAction={props.executeAction}
					projectId={params.id}
				/>
			</SubHeaderPortal>

			<div className="Body pr-2">
				<MaskableComponent masked={uiContext.maskedZones['main']}>
					<div className="content pt-1 pb-3 d-flex flex-column align-items-center">

						<h2>{t('pages.indicators.title')}</h2>

						<h3 className="mb-4">{t('pages.indicators.subtitle')}</h3>

						<HorizontalVulnerabilityScale
							vulnerabilities={vulnerabilities}
							scenarios={props.scenarios}
							scenario={props.scenario}
						/>

						<div className="mb-3 mt-4 w-100 pr-2 pl-2" style={{ maxWidth: '1400px' }}>
							<div style={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto', width: '100%' }}>
								<Table
									pagination={false}
									dataSource={alteredIndicators}
									columns={columns}
									size="small"
									rowKey="id"
									className="mr-1"
								/>
							</div>
						</div>

						<div className="w-100 d-flex justify-content-center align-items-center">
							<div className="w-100 d-flex justify-content-center align-items-center">
								{indicators?.length < indicatorsLimit && (
									<AddButton
										onClick={handleCreateIndicator}
									/>
								)}
							</div>
						</div>

					</div>
				</MaskableComponent>
			</div>

			<SideMenu>
				{/* Contenu du menu latéral */}
			</SideMenu>

			{editedItem && (
				<IndicatorEditionModal
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleUpdateIndicator(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
						reference: editedItem?.reference,
						evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null,
					}}
				/>
			)}
		</div>
	);
};
